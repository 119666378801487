var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    {
      staticClass: "c-hero",
      class: {
        "c-hero--edit": _vm.inEditMode,
        "c-hero--hideoverflow": _vm.heroVideo,
      },
      style: _vm.style,
    },
    [
      _vm.heroImage
        ? _c("link", { attrs: { rel: "prefetch", href: _vm.width } })
        : _vm._e(),
      _vm._v(" "),
      _vm.heroVideo
        ? _c(
            "div",
            { staticClass: "c-hero__video" },
            [
              _c("MediaBlock", {
                attrs: {
                  videoEmbedCode:
                    _vm.heroVideo.content &&
                    _vm.heroVideo.content.videoEmbedCode,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.overlay ? _c("div", { staticClass: "c-hero__overlay" }) : _vm._e(),
      _vm._v(" "),
      _c("Container", { staticClass: "c-hero__content-wrapper" }, [
        _c(
          "div",
          { staticClass: "c-hero__content" },
          [
            _c("h1", {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "Title",
                  expression: "'Title'",
                },
              ],
              staticClass: "c-hero__content-title",
              class: {
                "c-hero__content-title--colored": _vm.changeTitleColor,
                "c-hero__content-title--less-margin": _vm.subtitle,
              },
              domProps: { innerHTML: _vm._s(_vm.title) },
            }),
            _vm._v(" "),
            _vm.subtitle || _vm.isEditable
              ? _c("XhtmlField", { attrs: { items: _vm.subtitle } })
              : _vm._e(),
            _vm._v(" "),
            _vm.url
              ? _c(
                  "Button",
                  {
                    staticClass: "c-hero__button",
                    attrs: {
                      isLarge: "",
                      href: _vm.url,
                      buttonStyle: _vm.changeLinkColor ? "filled" : null,
                    },
                  },
                  [_vm._v(_vm._s(_vm.buttonText))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.url2
              ? _c(
                  "Button",
                  {
                    staticClass: "c-hero__button",
                    attrs: {
                      isLarge: "",
                      href: _vm.url2,
                      buttonStyle: _vm.changeLinkColor ? "filled" : null,
                    },
                  },
                  [_vm._v(_vm._s(_vm.buttonText2))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.heroImage
              ? _c("epi-property", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.epiDisableEditing,
                      expression: "!epiDisableEditing",
                    },
                  ],
                  attrs: { "property-name": "HeroImage" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }