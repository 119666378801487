<template>
    <section class="c-heroEbook__container">
        <div class="c-heroEbook" :style="style" :class="{'c-heroEbook--edit': inEditMode}">
            <div class="c-heroEbook__content">
                <Container class="c-heroEbook__content-wrapper">
                    <Row>
                        <Column :md="13" class="c-ebook-page__left-content">
                            <h2 v-if="subtitle || isEditable" v-text="subtitle" class="c-heroEbook__subtitle"></h2>
                            <h1 class="c-heroEbook__content-title" :class="{'c-heroEbook__content-title--colored': changeTitleColor, '': ''}" v-epi-edit="'Title'" v-html="title"></h1>
                            <XhtmlField v-epi-edit="'IntroText'" class="o-ingress" v-if="introText || isEditable" :items="introText"/>

                            <epi-property v-show="!epiDisableEditing" property-name="HeroImage"></epi-property>
                        </Column>
                        <Column :md="10" :offsetMd="1" class="c-ebook-page__right-content" id="embed">
                            <div v-if="embed" class="c-heroEbook__embed" :style='cssVariables'>
                                <component :is="dynamicComponent"></component>
                            </div>
                        </Column>
                    </Row>
                </Container>
            </div>
        </div>

        <Container v-if="overlayImage" class="c-heroEbook__additionalImage container">
            <Row>
                <Column :md="10" :offsetMd="1">
                    <ResponsiveImage v-if="overlayImage" :alt="subtitle" :src="overlayImage.url" />
                </Column>
            </Row>
        </Container>

    </section>
</template>

<script>
import EpiProperty from '@/Scripts/components/EpiProperty.vue';
import { mapState } from 'vuex';
import { RELOADPAGE } from '@/Scripts/store/modules/appContext';
import { mapMutations } from 'vuex';

export default {
    props: ['title', 'subtitle', 'heroImage', 'introText', 'changeTitleColor', 'changeLinkColor', 'embed', 'overlayImage', 'formBackground', 'formText', 'formLabel'],
    components: {
        EpiProperty
    },
    mounted: function () {
        // Even dirtier hack to force loading script multiple times. Do not put this in my CV
        // needs three ticks for location to have updated
        if (this.embed) {
            this.$nextTick(function () {
                this.$nextTick(function () {
                    this.$nextTick(function () {
                        if (this.reloadPageValue === true) {
                            location.reload();
                        }
                        this.doReloadPage();
                    });
                });
            });
        }
    },
    computed: {
        ...mapState({
            epiDisableEditing: state => state.appContext.modalShowing,
            inEditMode: state => state.epiContext.inEditMode,
            reloadPageValue: state => state.appContext.reloadFor !== location.pathname
        }),
        dynamicComponent() {
            let embedHTML = this.embed;
            const parser = new DOMParser();
            const html = parser.parseFromString(this.embed, 'text/html');
            const scripts = html.getElementsByTagName('script');

            // Dirty hack to inject a timestamp to loading external javascript
            if (Object.keys(scripts).length) {
                embedHTML = embedHTML.replace(/\.js\?/, '.js?t=' + Date.now() + '&');

            }

            return {
                template: '<div>' + embedHTML + '</div>'
            };
        },
        style() {
            var style = {};
            if (this.heroImage) {
                style = { 'background-image': 'url(' + this.heroImage + '?w=3000)' };
            }
            return style;
        },
        cssVariables() {
            const background = this.formBackground ? this.formBackground : 'transparent';
            const textColor = this.formText ? this.formText : 'inherit';
            const labelColor = this.formLabel ? this.formLabel : 'inherit';

            return {
                '--form-bg-color': background,
                '--form-text-color': textColor,
                '--form-label-color': labelColor
            };
        }
    },
    methods: {
        ...mapMutations({
            doReloadPage: RELOADPAGE
        })
    },
};
</script>

<style lang="scss">
.c-heroEbook {
    width: 100vw;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    padding-top: 80px;
    margin-bottom: 0;

    .u-add-colored-period::after {
        font-size: inherit;
    }

    @include media-md {
        /*max-height: 100vh;*/
        padding-top: 200px;
        padding-bottom: 200px;
        margin-bottom: 0;
    }


    @include media-md {
        &__container {
            background-color: $beige;
            position: relative;
        }
    }

    &__content-wrapper {
        height: 100%;
        display: flex;
        align-items: center;

        > .row {
            width: 100%;
        }
    }
    &__content {
        color: white;
        z-index: 1;

        &-title {
            margin-top: 0;
            font-size: 32px;
            line-height: 42px;

            @include media-sm {
                font-size: 52px;
                line-height: 62px;
            }
            @include media-md {
                font-size: 56px;
                line-height: 64px;
            }
            &--colored {
                color: $text-color
            }
            &--less-margin {
                margin-bottom: 1rem;
            }
        }
    }
    &--edit {
        height: 750px;
    }

    &__subtitle {
        font-size: 14px;
        line-height: 1;
        color: $orange;
        margin: 0 0 30px 0;
        text-transform: uppercase;
    }

    &__additionalImage {
        display: none;
        @include media-md {
            display: block;

            img {
                margin-top: -180px;
                margin-bottom: 100px;
            }
        }
    }

    &__embed {
        width: 100%;
        position: relative;

        background-color: var(--form-bg-color);
        color: var(--form-text-color);

        label {
            color: var(--form-label-color);
        }

        @include media-md {
            margin-bottom: -400px;
        }

        iframe {
            width: 100%;
            padding: 10px;
        }

        &:has(form) {
            margin-bottom: 0 !important;
            border-radius: 0.7rem !important;
        }

        &:has(form) form.marketingForm{
            padding: 1rem !important;
            background-color: transparent !important;
        }
    }
}

.c-ebook-page__right-content {
    padding-top: 44px;
}
</style>
