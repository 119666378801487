<template>
    <main class="practicearea">
        <!-- Hero -->
        <PracticeAreaHero :model="model" />
        <!-- // Hero -->

        <!-- Navigation -->
        <Container isFluid class="practicearea--navigation" v-if="notEmptyObject(model.siblings) || isEditable">
            <Container class="u-hidden-xs u-hidden-sm">
                <ul class="o-flat-list practicearea--navigation__list">
                    <li v-for="(item, index) in model.siblings.siblings" :key="`${_uid}${index}`" class="practicearea--navigation__list--element">
                        <Anchor :class="compareGuid(item.guid) ? 'practicearea--navigation__list--selected' : ''" :href="item.url" :ariaLabel="item.title">{{item.title}}</Anchor>
                    </li>
                </ul>
            </Container>
            <Dropdown class="u-display-xs u-display-sm" v-model="dropdownSelection" @input="changeRoute" :options="dropdownValues" :clearable="false"/>
        </Container>
        <!-- // Navigation -->

        <!-- Description -->
        <Container class="practicearea--description">
            <Row class="o-block c-two-column-block">
                <Column :md="12" class="practicearea--description__text">
                    <h2 v-if="model.descriptionHeading" v-epi-edit="'TitleCol1'" class="practicearea--global__title">{{model.descriptionHeading}}</h2>
                    <div class="c-two-column-block__body1"><p v-html="model.descriptionText.replace(/\u200B/g,'</p>')"></p></div>
                </Column>

                <Column :md="12" class="c-two-column-block__body2">
                    <MediaBlock class="c-service-page__media-block" v-if="notEmptyObject(model.media && model.media.content) || isEditable" v-bind="model.media.content" v-epi-edit="'Media'"/>
                </Column>
            </Row>
        </Container>
        <!-- // Description -->

        <!-- Benefits -->
        <div class="practicearea--benefits">
            <Container>
                <Row>
                    <Column>
                        <Heading :level="2" class="practicearea--global__title">{{model.benefitsHeading}}</Heading>
                    </Column>
                </Row>
                <Row class="practicearea--benefits__list">
                    <Column :md="8" v-for="(item, index) in model.benefits" :key="`${_uid}${index}`">
                        <div class="practicearea--benefits__card">
                            <div class="practicearea--benefits__card__image-wrapper">
                                <AspectRatioBox>
                                    <ResponsiveImage :src="item.image" :alt="item.benefitTitle" :xs="{h: 120, w: 120}" :sm="{h: 120, w: 120}" :md="{h: 150, w: 150}" :lg="{h: 150, w: 150}" class="practicearea--benefits__card__image"/>
                                </AspectRatioBox>
                            </div>
                            <Heading :level="3" class="practicearea--benefits__card__header">{{item.benefitTitle}}</Heading>
                            <p class="practicearea--benefits__card__text">{{item.paragraph}}</p>
                        </div>
                    </Column>
                </Row>
            </Container>
        </div>
        <!-- // Benefits -->

        <!-- Expertise -->
        <Container class="practicearea--expertise">
            <Row>
                <Column>
                    <Heading :level="2" class="practicearea--global__title">{{ model.expertiseAreasHeading }}</Heading>
                </Column>
                <Column>
                    <ContentArea v-epi-edit="'Blocks'" v-if="model.expertiseAreas || isEditable" class="practicearea--expertise__single" :model="model.expertiseAreas  || isEditable"/>
                </Column>
            </Row>
        </Container>
        <!-- // Expertise -->

        <!-- Success stories -->
        <div class="practicearea--articlelist">
            <Container>
                <Row>
                    <Column :md="24">
                        <Heading :level="2" class="practicearea--global__title">{{model.successStoriesHeading}}</Heading>
                    </Column>
                </Row>
                <Row v-if="model.successStoriesPreamble !== ''">
                    <Column :md="16">
                        <p class="practicearea--articlelist__preamble">
                            {{ model.successStoriesPreamble }}
                        </p>
                    </Column>
                </Row>
                <Row class="c-page-list--large">
                    <Column :md="6" v-for="(item,index) in model.successStories" :key="index" class="c-page-list--large__item">
                        <div class="c-page-list--large__content">
                            <AspectRatioBox :width="323" :height="193">
                                <ResponsiveImage :src="item.imageUrl" v-bind="imageConfig"/>
                            </AspectRatioBox>
                            <div class="c-page-list--large__content__text">
                                <Heading isCategory>{{item.label}}</Heading>
                                <Heading :level="5" class="c-page-list--large__heading u-no-margin-top">{{item.title}}</Heading>
                                <v-clamp class="o-large-body" autoresize :max-lines="3" tag="p">{{item.intro}}</v-clamp>
                                <Anchor class="c-page-list--large__link" isFancy :href="item.url" :ariaLabel="item.title">{{getLabel('global', 'readMore')}}</Anchor>
                            </div>
                        </div>
                    </Column>
                </Row>
            </Container>
        </div>
        <!-- // Success stories -->

        <!-- Vendors -->
        <Container>
            <Row>
                <Column>
                    <PracticeAreaVendors v-bind:model="model" />
                </Column>
            </Row>
        </Container>
        <!-- // Vendors -->

        <!-- Why Crayon -->
       <div class="practicearea--accordion o-bg">
            <Container>
                <Row v-if="model.whyCrayonHeading !== ''">
                    <Column :md="10" class="practicearea--accordion__block">
                        <Heading :level="2" class="practicearea--global__title">{{ model.whyCrayonHeading }}</Heading>
                    </Column>
                </Row>
                <div>
                    <div v-for="(item, index) in model.whyCrayonItems" :key="index" class="accordion-item practicearea--accordion__item">
                        <div class="practicearea--accordion__header" @click="toggleItem(index)">
                            <span>
                            <div class="practicearea--accordion__header">
                                <div> {{ item.heading }}</div>
                            </div>
                            </span>
                            <svg class="toggle-sign">
                            <use :xlink:href="activeIndex === index ? '#minus' : '#plus'"></use>
                            </svg>
                        </div>
                        <transition name="accordion">
                                <div v-show="activeIndex === index" class="practicearea--accordion__content">
                                <XhtmlField :items="item.content"></XhtmlField>
                                </div>
                        </transition>
                    </div>
                </div>
            </Container>
        </div>
        <!-- /Why Crayon -->


        <!-- Contact form -->
        <Container class="practicearea--contact" ref="contactform">
            <Row>
                <Column :md="12" class="practicearea--contact__textarea">
                    <Heading :level="2" class="practicearea--global__title">{{ model.contactFormHeading }}</Heading>
                    <XhtmlField v-epi-edit="'model.contactFormIntro'" :items="model.contactFormIntro"/>
                    <ResponsiveImage :src="model.contactFormIllustration" v-bind="imageConfig"/>
                </Column>
                <Column :md="12" class="practicearea--contact__form">
                    <!-- Contact form script -->
                    <PracticeAreaFormBlock v-epi-edit="'Form'" id="form" v-if="notEmptyObject(model.formData) || isEditable" v-bind="model.formData"/>
                </Column>
            </Row>
        </Container>
        <!-- // Contact form -->

        <!-- Explore more -->
        <div class="practicearea--articlelist">
            <Container>
                <Row>
                    <Column :md="24">
                        <Heading :level="2" class="practicearea--global__title">{{model.exploreMoreHeading}}</Heading>
                    </Column>
                </Row>
                <Row v-if="model.exploreMorePreamble !== ''">
                    <Column :md="16">
                        <p class="practicearea--articlelist__preamble">
                            {{ model.exploreMorePreamble }}
                        </p>
                    </Column>
                </Row>
                <Row class="c-page-list--large">
                    <Column :md="6" v-for="(item,index) in model.exploreMore" :key="index" class="c-page-list--large__item">
                        <div class="c-page-list--large__content">
                            <AspectRatioBox :width="323" :height="193">
                                <ResponsiveImage :src="item.imageUrl" v-bind="imageConfig"/>
                            </AspectRatioBox>
                            <div class="c-page-list--large__content__text">
                                <Heading isCategory>{{item.label}}</Heading>
                                <Heading :level="5" class="c-page-list--large__heading u-no-margin-top">{{item.title}}</Heading>
                                <v-clamp class="o-large-body" autoresize :max-lines="3" tag="p">{{item.intro}}</v-clamp>
                                <Anchor class="c-page-list--large__link" isFancy :href="item.url" :ariaLabel="item.title">{{getLabel('global', 'readMore')}}</Anchor>
                            </div>
                        </div>
                    </Column>
                </Row>
            </Container>
        </div>
        <!-- // Explore more -->

    </main>
</template>

<script>
import Dropdown from '@/Scripts/components/atoms/Dropdown.vue';
import ContentArea from '@/Scripts/components/ContentArea.vue';
import AspectRatioBox from '@/Scripts/components/atoms/AspectRatioBox.vue';
import VClamp from 'vue-clamp';
import Icon from '@/Scripts/components/atoms/Icon.vue';
import Datetime from '@/Scripts/components/atoms/DateTime.vue';
import PracticeAreaFormBlock from '@/Scripts/components/blocks/PracticeAreaFormBlock.vue';
import PracticeAreaVendors from '@/Scripts/components/molecules/PracticeAreaVendors.vue';
import PracticeAreaHero from '@/Scripts/components/molecules/PracticeAreaHero.vue';

export default {
    props: ['model'],
    data() {
        return {
            dropdownSelection: null,
            selected: '',
            activeIndex: null,
        };
    },
    mounted() {
        this.dropdownSelection = this.currentSibling;
    },
    computed: {
        currentSibling() {
            if (this.model.siblings && this.model.siblings.siblings) {
                var current =  this.model.siblings.siblings.find(x => x.url === this.$route.path);
                return {label: current.title, url: current.url };
            }
        },
        dropdownValues() {
            var values = [];
            if  (this.model.siblings && this.model.siblings.siblings) {
                values = this.model.siblings.siblings.map(x => {
                    return {label: x.title, url: x.url};
                });
            }
            return values;
        },
    },
    methods: {
        changeRoute(current) {
            this.$router.push(current.url)
                .then(() => location.reload());
        },
        changeTab(item) {
            this.selected = item.title;
            this.$router.push(item.url)
                .then(() => {
                    if (this.model.form && this.model.form !== null) {
                        location.reload();
                    }
                });
        },
        isInPath(item) {
            var pathName = window.location.pathname;
            return (pathName.toLowerCase().trim().includes(item.title.replace(/\s+/g, '-').toLowerCase().trim())
                || pathName.toLowerCase().trim() === item.url.toLowerCase().trim());
        },
        toggleItem(index) {
            this.activeIndex = this.activeIndex === index ? null : index;
        },
        compareGuid(guid) {
            if (this.model.contentLink.guidValue === guid) {
                return true;
            }
            return false;
        },
        scrollToContactform() {
            this.$refs.contactform.$el.scrollIntoView({ behavior: 'smooth' });
        }
    },
    components: {
        PracticeAreaFormBlock,
        Datetime, Icon, VClamp,
        AspectRatioBox,
        Dropdown,
        ContentArea,
        PracticeAreaVendors,
        PracticeAreaHero
    }
};
</script>

<style lang="scss">
</style>
