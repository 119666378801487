<!-- TODO: Fix video player. Probably shouldnt be standard HTML5 player. Also: only accepts mp4 right now -->
<template>
    <figure v-if="!videoEmbedCode">
        <video v-if="video" class="c-video-block" controls>
            <source :src="video" type="video/mp4">
            Your browser does not support the video tag.
        </video>
         <ResponsiveImage v-else-if="image" :alt="subtitle" :src="image" v-bind="imageConfig"/>
        <figcaption v-if="subtitle">{{subtitle}}</figcaption>
    </figure>
    <figure v-else>
      <div v-if="videoEmbedCode" v-html="videoEmbedCode" class="c-video-block"></div>
      <ResponsiveImage v-else-if="image" :alt="subtitle" :src="image" v-bind="imageConfig"/>
      <figcaption v-if="subtitle">{{subtitle}}</figcaption>
    </figure>
</template>

<script>
export default {
    props: ['video', 'image', 'subtitle', 'imageConfig', 'videoEmbedCode']
};
</script>

<style lang="scss">
.c-video-block {
    width: 100%;
}
</style>
