<template>
<Row class="c-form-block">
    <Column :md="10">
        <Heading noMarginTop>{{title}}</Heading>
    </Column>
    <Column :md="8" :offsetMd="4">
        <component :is="dynamicComponent"></component>
    </Column>
</Row>
</template>

<script>
import { mapState } from 'vuex';
import { RELOADPAGE } from '@/Scripts/store/modules/appContext';
import { mapMutations } from 'vuex';

export default {
    props: {
        title: String,
        formData: String
    },
    mounted: function () {
        // Even dirtier hack to force loading script multiple times. Do not put this in my CV
        // needs three ticks for location to have updated
        this.$nextTick(function () {
            this.$nextTick(function () {
                this.$nextTick(function () {
                    if (this.reloadPageValue === true) {
                        location.reload();
                    }
                    this.doReloadPage();
                });
            });
        });
    },
    computed: {
        ...mapState({
            epiDisableEditing: state => state.appContext.modalShowing,
            inEditMode: state => state.epiContext.inEditMode,
            reloadPageValue: state => state.appContext.reloadFor !== location.pathname
        }),
        dynamicComponent() {
            let embedHTML = this.formData;
            const parser = new DOMParser();
            const html = parser.parseFromString(this.formData, 'text/html');
            const scripts = html.getElementsByTagName('script');

            // To please Vue, pick src from embed script and append it again
            for (let i = 0; i < scripts.length; i++) {
                const script = document.createElement('script');
                script.src = scripts[i].getAttribute('src') + '?t=' + Date.now();
                document.body.appendChild(script);
            }

            // Remove script from original embeded code
            embedHTML = embedHTML.replace(/<script.*(\/script>)/gs, '');

            //check if iframe
            if (embedHTML.indexOf('<iframe') > -1) {
                //get iframe

                const iframe = embedHTML.match(/<iframe[^>]*>(.*?)<\/iframe>/g)[0];

                //set styling on iframe

                const iframeWithStyle = iframe.replace('<iframe', '<iframe style="width:100%;height:100%;"');

                return {
                    template: `<div class="c-iframe-block">${iframeWithStyle}</div>`
                };
            }
            return {
                template: '<div>' + embedHTML + '</div>'
            };
        },
    },
    methods: {
        ...mapMutations({
            doReloadPage: RELOADPAGE
        })
    },
};
</script>

<style lang="scss">
.c-form-block {
    margin-top: 3rem;
    margin-bottom: 3rem;

    @include media-md {
        margin-top: 7rem;
        margin-bottom: 7rem;
    }
}

.c-iframe-block {
        width: 100%;
        height: 35rem;
}

/* Style and overrid CRM-forms */
.c-form-block form.marketingForm{
    width: 100%;
    font-family: inherit !important;
    font-size: 1em !important;
    color: #04242D !important;
    font-family: sofia-pro,sans-serif !important;

    p,
    li,
    th,
    td,
    label,
    input,
    * {
        color: #04242D !important;
        font-family: sofia-pro,sans-serif !important;
    }

    div {
        width: 100% !important;
        max-width: 100% !important;
        text-align: left;
        margin: 0 !important;
        padding: 0 !important;
        display: block;
        gap: 0;
        position: relative;
    }

    table {
        width: 100% !important;
        margin: 0 !important;

        th, td, tbody, thead, tr {
            padding: 0 !important;
            margin: 0 !important;
            border: 0 none !important;
        }

        tbody, thead, tr {
            width: 100% !important;
            min-width: 100% !important;
        }

    }

    h1 {
        margin-block-end: 24px;
        margin: 0 0 24px 0;
        font-size: 28px;
        font-size: 1.75rem;
        margin-block-start: 0;
    }

    p {
        font-size: 16px;
        font-size: 1rem;
        margin: 0 0 0 0;
        margin-block-start: 0;
        margin-block-end: 0;
    }

    p:last-child {
        margin: 0 0 24px 0;
        margin-block-start: 0;
        margin-block-end: 24px;
    }

    label {
        font-size: 16px;
        font-size: 1rem;
        font-weight: 400;
        margin: 0 0 4px 0;
        display: block;
        cursor: pointer;
        font-family: sofia-pro,sans-serif !important;
        color: #04242D !important;
    }

    input[type=text],
    input[type=date],
    input[type=datetime-local],
    input[type=email],
    input[type=month],
    input[type=number],
    input[type=search],
    input[type=time],
    input[type=url],
    input[type=week],
    input[type=tel] {
        display: block;
        width: 100%;
        padding: 16px;
        margin: 0 0 16px 0;
        border-radius: 4px;
        border: 1px solid #0A4F5B;
        filter: none !important;
        background-color: #fff;
        font-size: 16px;
        font-size: 1rem;

        &:hover,
        &:focus {
            outline: 1px solid #0A4F5B;
        }
    }

    input[type=checkbox] {
        margin-right: 8px;
        vertical-align: bottom;
        position: absolute;
        width: 27px !important;
        height: 27px !important;
        opacity: 0;
    }

    input[type=checkbox] + label {
        display: inline;
        vertical-align: middle;
        position: relative;
        padding-left: 30px;

        &::before,
        &::after {
            content: " ";
            color: white;
            display: inline-block;
            border: 1px solid #0A4F5B;
            border-radius: 4px;
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    input[type=checkbox]:checked + label {
        &::before {
            outline: 1px solid #0A4F5B;
        }

        &::after {
            content: " ";
            background-color: #0A4F5B;
            width: 18px;
            height: 18px;
            top: 3px;
            left: 3px;
            display: block;
        }
    }
}

</style>
